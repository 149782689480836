.btn-choice-outline-main-color-correct{
    background-color: rgb(243, 251, 245);
    border: 1px solid rgb(50, 149, 70);
}
.btn-choice-outline-main-color-correct:hover {
    background-color: rgb(243, 251, 245);
    border: 1px solid rgb(50, 149, 70);
    cursor: pointer;
}
.btn-key-correct {
    /* border: solid 2px var(--main-color) !important; */
    border-radius: 50%;
    padding: 0px 8px;
    width: 25px;
    /* Đặt chiều rộng */
    height: 25px;
    /* Đặt chiều cao */
    display: flex;
    align-items: center;
    margin-right: 1.5vw;
    text-align: center;
    color: #ffffff;
    background-color: rgb(50, 149, 70);
    font-size: 16px;
    justify-content: center;
    /* line-height: 24px; */
}
.btn-choice-outline-main-color-incorrect{
    background-color: rgb(255, 235, 235);
    border: 1px solid rgb(255, 100, 100);
   
}
.btn-choice-outline-main-color-incorrect:hover {
    background-color: rgb(255, 235, 235);
    border: 1px solid rgb(255, 100, 100);

    cursor: pointer;
}
.btn-key-incorrect {
    /* border: solid 2px var(--main-color) !important; */
    border-radius: 50%;
    padding: 0px 8px;
    width: 25px;
    /* Đặt chiều rộng */
    height: 25px;
    /* Đặt chiều cao */
    display: flex;
    align-items: center;
    margin-right: 1.5vw;
    text-align: center;
    color: #ffffff;
    background-color: rgb(255, 100, 100);
    font-size: 16px;
    justify-content: center;
    /* line-height: 24px; */
}
