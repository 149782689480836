.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 10px;
}
.logo-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    border-radius: 50%;
    background-color: rgba(28, 17,41, 0.88);
}

.logo-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}