.botton-section-review {
  background-color: var(--dark-medium-color);
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
  width: 100%;
  z-index: 1000;
  /* Ensure it stays on top of other elements */
}
.question-number-review {
  font-size: 15px;
  padding: 5px 10px;
  color: #fff;
  background-color: #000;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.question-number-correct {
  font-size: 15px;
  padding: 5px 10px;
  color: #fff;
  background-color: #329546;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.question-number-incorrect {
  font-size: 15px;
  padding: 5px 10px;
  color: #fff;
  background-color: #e64919;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-container-correct {
  align-items: center;
  display: flex;
  background-color: rgb(243, 251, 245);
  color: rgb(50, 149, 70);
}
.title-container-incorrect {
  align-items: center;
  display: flex;
  background-color: rgb(254, 248, 245);
  color: #e64919;
}
.question-number-skipped {
  font-size: 15px;
  padding: 5px 10px;
  color: #fff;
  background-color: rgb(255, 153, 31);
  text-align: center;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.question-number-normal {
  font-size: 15px;
  padding: 5px 10px;
  color: #fff;
  background-color: rgb(49, 49, 48);
  text-align: center;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-container-skipped {
  align-items: center;
  display: flex;
  background-color: rgb(255, 250, 244);
  color: rgb(255, 153, 31);
}
.title-container-normal {
  align-items: center;
  display: flex;
  background-color: rgb(255, 250, 244);
  color: rgb(49, 49, 48);
}
.math-correct{
  color: rgb(50, 149, 70);
}
.math-incorrect{
  color: #e64919;
}
.math-skipped{
  color: rgb(255, 153, 31);
}

.btn-choice-outline-main-color-correct tbody,
.btn-choice-outline-main-color-correct td,
.btn-choice-outline-main-color-correct tr {
    text-align: center !important;
    border: solid 1px #000000 !important;
    padding: 5px;

}

.btn-choice-outline-main-color-correct th {
  text-align: center !important;
  background-color: #f2f2f2 !important;
  border: solid 1px #000000 !important;
  padding: 5px;

}