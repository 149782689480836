/* MockList.css */

/* Status styles */
.status-finish {
    background-color: #d4edda;
    color: #155724;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .status-in-progress {
    background-color: #fff3cd;
    color: #856404;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .status-not-started {
    background-color: #f8d7da;
    color: #721c24;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
  }
  