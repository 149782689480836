.btn-outline-main-color > table {
    width: 100%;
    border-collapse: collapse !important;
}

.btn-outline-main-color th, .btn-outline-main-color td {
    border: 1px solid black !important;
    padding: 8px;
    text-align: left;
}

.btn-outline-main-color th {
    background-color: #f2f2f2 !important;
}
