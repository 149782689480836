/* Đặt toàn bộ container ở giữa màn hình */
.login-container {
    height: 100vh;
    background-color: #f8f9fa; /* Màu nền nhẹ cho toàn màn hình */
  }
  
  .register-form {
    width: 100%;
    max-width: 400px; /* Giới hạn chiều rộng của form */
    background-color: #fff; /* Màu nền của form */
    border-radius: 8px; /* Bo tròn các góc form */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Tạo bóng cho form */
  }
  
  .register-form h2 {
    font-size: 24px;
    color: #333; /* Màu chữ tiêu đề */
  }
  
  .form-group label {
    font-size: 14px;
    font-weight: bold;
    color: #495057; /* Màu cho label */
  }
  
  .form-group input {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ced4da; /* Đường viền nhẹ cho input */
    border-radius: 4px;
    margin-top: 5px;
    width: 100%;
  }
  
  .form-group input:focus {
    border-color: #80bdff;
    outline: none;
    box-shadow: 0 0 5px rgba(128, 189, 255, 0.5); /* Hiệu ứng khi focus vào input */
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .btn-primary:hover {
    background-color: #0056b3; /* Hiệu ứng khi hover lên nút đăng ký */
    border-color: #004085;
  }
  
  .text-center {
    text-align: center;
  }
  
  .mt-4 {
    margin-top: 1.5rem;
  }
  
  .mb-4 {
    margin-bottom: 1.5rem;
  }
  
  .p-4 {
    padding: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  