 /* Loại bỏ đường viền dọc */
 /* .table-no-vertical td,
 .table-no-vertical th {
     border-left: none;
     border-right: none;
 } */
 .correct-answer {
     color: rgb(50, 149, 70) !important;
     font-weight: 500;
 }
 .wrong-answer {
     color: #d14242 !important;
     font-weight: 500;
 }
 .skip-answer{
        
        color: #ff991f !important;
        font-weight: 500;
 }

 .score-page-header h1{
    font-size: 4rem;
 }

 .score-page-logo-container {
    width: 100%;
    aspect-ratio: 1; /* Tạo container hình vuông */
}

.score-page-logo {
    max-width: 100%;
    object-fit: contain;
}

.table-row-light {
    background-color: #ffffff;
}

.table-row-dark {
    background-color: #fafafa;
}


 