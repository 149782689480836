/* QuestionsMenu.css */
.questions-menu {
    position: absolute;
    bottom: 200%; /* Hiển thị phía trên */
    left: 0;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.menu-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;


}

.btn-question{
    border-radius: 3px;
    width:35px;
    position: relative;
}
.exit-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: 800 !important;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff !important;
    border-color: #fff !important;
}




/* Trạng thái đã chọn (hoàn thành) */
.btn-selected {
    background-color: #28a745 !important;
    /* Màu nền xanh lá cây */
    color: #fff !important;
    /* Màu chữ trắng */
    border: 1px solid #28a745 !important;
    /* Đường viền màu xanh lá cây */
}

.current-css {
    color: black;
    position: absolute;
    top: -16px;
    right: 10px;
}
.mark-css{
    position: absolute;
    top: -4px;
    right: -4px;
}
