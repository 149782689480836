:root {
    --main-color: #2f2964;
    --muted-main-color: #6d6993;
    --dark-medium-color: #a19fbd;
    --medium-color: #d5d4e0;
    --light-bg: #f5f5f8;
    --light-blue: #e8f0fc;
    --dark-blue: #0e4fab;
    --medium-blue: #475496;
    --superlight-purple: #f7f5fc;
    --light-purple: #c1aeeb;
    --medium-purple: #8c66df;
    --dark-purple: #5823cc;
}