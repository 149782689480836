
.highlighted-text {
  display: inline-block;
}

.highlight {
  cursor: pointer;
}
.highlight-container {
  position: relative;
}

.highlight-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #6f6d6d;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 2px 10px rgba(148, 141, 141, 0.2);
  z-index: 10;
}

.highlight-menu-btn {
  margin: 2px;
  border: 1.2px solid #9E9A88;
  border-radius: 50px;
  background-color: #fff;
  cursor: pointer;
}
.yellow {
  color: #fef9d8 !important;
  background-color: #fef9d8 !important;
}
.yellow:hover {
  color: #f8e495 !important;
  background-color: #f8e495 !important;
}

.pink {
  color: #FFE4F8;
  background-color: #FFE4F8;
}
.pink:hover {
  color: #FFCFF0 !important;
  background-color: #FFCFF0 !important;
}
.cyan {
  color: #E8F2FC;
  background-color: #E8F2FC;
}
.cyan:hover {
  color: #B3DFFE !important;
  background-color: #B3DFFE !important;
}
.highlight-menu-btn:hover {
  border: 1.2px solid #000000;
}
.highlight-menu-btn h6 {
  margin: 6.5px 3px 6.5px 3px !important;
}



