.login-form {
  width: 100%;
  max-width: 400px; /* Giới hạn chiều rộng của form */
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 5%;
}
.login-container {
  height: 100vh;
  background-color: white !important;
}
.btn-block {
  width: 100%;
}
