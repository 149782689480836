.percent-bar-element-false{
    width: 15%;
    height: 8px;
    background-color: #ffffff;
    border: 1px solid #202020;
    /* border-radius: 30px; */
}
.percent-bar-element-true{
    width: 15%;
    height: 8px;
    background-color: #202020;
    border: 1px solid #202020;
    /* border-radius: 30px; */
}