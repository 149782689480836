/* src/pages/TestCompletion/TestCompletion.css */
.completion-container {
    background-color: #f8f9fa;
    min-height: 100vh;
}

.card {
    max-width: 500px;
    border: none;
    background-color: #ffffff;
}

.card-title {
    font-weight: bold;
    color: #28a745;
}
