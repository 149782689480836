.main-card-test {
    max-width: 1400px;
    background-color: #fff;
    overflow: hidden;
}
.main-header-test{
    z-index: -1000;
}

.content>div {
    overflow: hidden
}

.view-more {
    justify-content: end;
    width: 200px;

}

.row-with-divider {

    position: relative;
    min-height: 400px;
    max-height: 770px;
    height: calc(100vh - 230px);
}

.mid-section {
    max-height: 800px;
}

.title-container {
    background-color: rgb(238, 238, 238);
}

.question-number {
    font-size: 15px;
    padding: 5px 10px;
    color: #fff;
    background-color: #000;
    text-align: center;
    display: inline-block;
    margin-bottom: 0;

}

.mark-btn {
    outline: none;
    box-shadow: none;
}

.marked {
    color: red !important;
}

@media (min-width: 768px) {
    .left-divider {
        border-right: 1px solid #000;
        /* Đường kẻ màu đen */

    }
}

.btn-outline-main-color {
    color: var(--main-color);
    border: 1px solid var(--main-color) !important;
}

.btn-outline-main-color:hover {
    /* color: #fff !important;
    background-color: var(--main-color) !important; */
    cursor: pointer;
}

.btn-outline-main-color-selected {
    color: #fff !important;
    background-color: var(--main-color) !important;
    border: solid 2px var(--main-color) !important;
}

.btn-choice-outline-main-color-selected {
    /* color: #fff !important; */
    /* background-color: var(--main-color) !important; */
    border: solid 2px var(--main-color) !important;
    text-align: center !important;
}

.btn-choice-outline-main-color-selected tr {
    text-align: center !important;
    border: solid 1px #000 !important;
}

.btn-choice-outline-main-color-selected td {
    border: 1px solid black !important;
    padding: 8px;
}

.btn-key {
    border: solid 2px var(--main-color) !important;
    border-radius: 50%;
    padding: 0px 8px;
    width: 25px;
    /* Đặt chiều rộng */
    height: 25px;
    /* Đặt chiều cao */
    display: flex;
    align-items: center;
    margin-right: 1.5vw;
    text-align: center;
    font-size: 16px;
    justify-content: center;
    /* line-height: 24px; */
}

.btn-key-selected {
    /* border: solid 2px var(--main-color) !important; */
    border-radius: 50%;
    padding: 0px 8px;
    width: 25px;
    /* Đặt chiều rộng */
    height: 25px;
    /* Đặt chiều cao */
    display: flex;
    align-items: center;
    margin-right: 1.5vw;
    text-align: center;
    color: #ffffff;
    background-color: var(--main-color);
    font-size: 16px;
    justify-content: center;
    /* line-height: 24px; */
}

.strikethrough-box {
    position: relative;
    color: #bbb !important;
    border: 1px solid #bbb !important;

}

.strikethrough-box:after {
    content: "";
    position: absolute;
    top: 50%;
    left: -1%;
    width: 102%;
    height: 2px;
    background-color: #000;
    transform: translateY(-50%);
}

.botton-section {
    background-color: var(--dark-medium-color);
    min-height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;

    z-index: 1000;
    /* Ensure it stays on top of other elements */
}

/* Basic css for table */

/* Basic table styling */
table {

    border-collapse: collapse;
    /* Remove space between table cells */
    margin: 20px 0;
    /* Add some margin around the table */

}

/* Table header styling */
th {
    border: 1px solid #000;
    /* Add a border to all table headers */
    padding: 5px;
}




.btn-outline-main-color tbody,
.btn-outline-main-color td,
.btn-outline-main-color tr {
    text-align: center !important;
    border: solid 1px #000 !important;

}

.btn-outline-main-color-selected tbody,
.btn-outline-main-color-selected td,
.btn-outline-main-color-selected tr {
    text-align: center !important;
    border: solid 1px #000000 !important;
    padding: 10px !important;
}
.data-table tbody,
.data-table td,
.data-table tr {
    padding: 10px !important;
}

.question-body,
.question-body td,
.question-body th,
.question-body tr {
    text-align: center !important;
    /* Bottom border for table cells */
    border-width: 1px !important;
}
.question-body th {
   
    background-color: #f2f2f2 !important;
    
}
.btn-outline-main-color:hover tbody,
.btn-outline-main-color:hover td,
.btn-outline-main-color:hover tr {
    text-align: center !important;
    border: solid #101010 !important;
    /* Bottom border for table cells */
    border-width: 1px !important;
}


.bottom-section {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    /* Set the background color */
    z-index: 1000;
    /* Ensure it stays on top of other elements */
}

.choice-input {
    max-width: 27vh;
    height: 10vh;
}

.question-menu-container {
    position: relative;
    display: inline-block;
}

.relative {
    position: relative;
}

.bg-lightgreen {
    background-color: #15f5ba;
}

.bg-red {
    background-color: #ff407d;
}

.select-none {
    user-select: none;
}

.bg-yellow {
    background-color: #f5dd61;
}

.bg-blue {
    background-color: #59d5e0;
}

.desmos-popup-small {
    position: fixed;
    top: 10%;
    left: 0%;
    width: 35%;
    height: 80%;
    background-color: white;
    z-index: 1000;
    border: 1px solid black;
    padding: 10px;
}

.left-divider table {
    width: 100%;
    border-collapse: collapse !important;
}

.left-divider th,
.left-divider td {
    border: 1px solid black !important;
    padding: 8px;
    text-align: left;
}

.left-divider th {
    background-color: #f2f2f2 !important;
}

.highlight-hr-on {
    position: relative;
    margin-top: -17px;
    border: 1px solid #000000 !important;
}
.highlight-hr-off {
    position: relative;
    margin-top: -15px;
    display: none;
}